/* You can add global styles to this file, and also import other style files */
/* @import "~ag-grid-community/dist/styles/ag-grid.css"; 
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/primeng/resources/primeng.min.css"; 
@import "../node_modules/primeng/resources/themes/rhea/theme.css"; 
@import "../node_modules/primeicons/primeicons.css"; */


/* @import "~bootstrap/dist/css/bootstrap.min.css"; */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/primeflex/primeflex.css";
@import "../node_modules/primeng/resources/themes/rhea/theme.css"; 
@import "../node_modules/primeng/resources/primeng.min.css"; 
@import "../node_modules/primeicons/primeicons.css";

html, body { 
    height: 100%;   
    font-size: 13px;  
    font-weight: bolder;
    font-family: Roboto, Arial, sans-serif;
}
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
/* 
body {
    font-family: Roboto, Arial, sans-serif;
    font-weight: bolder;
    margin: 0;
} */



.basic-container {
    padding: 30px;
}
.version-info {
    font-size: 10pt;
    text-align: left;
    float: left;
}

.prime-table{
    font-size: 0.8em;
    font-weight: bolder;
  }


.ui-widget {
    font-size: 12px !important;
    font-weight: bolder;
 }

 .ui-dropdown {
    font-size: 12px !important;
    font-weight: bolder;
 }

 .ui-dropdown-label {
    font-size: 12px !important;
    font-weight: bolder;
}

 .ui-card {
    background-color: LIGHTGRAY !important;
    font-weight: bolder;
  }

  .ui-card-shadow {
    background-color:rgb(149, 165, 166) !important;
    font-weight: bolder;
    color: #E4E4E4;
    border: 1px solid rgb(52, 73, 94);
    border-radius: 1em;
  }

  .ui-card-light {
    /* background-color: #a4b3b6!important; */
    /* background-color: #c2ced0!important; */
    background-color: Gainsboro!important;
    /* font-weight: bolder; */
    /* color: white; */
    color: darkslategrey;
    border: 2px groove SteelBlue;
    /* border-style: groove; */
  }

  .ui-card-ultra-light {
    background-color: AliceBlue!important;
    font-weight: bolder;
    color: white;
    border: 2px ridge Gainsboro;
    border-radius: 1em;
  }

  .ui-card-ultra-light_sized {
    background-color: AliceBlue!important;
    font-weight: bolder;
    color: white;
    border: 2px ridge Gainsboro;
    border-radius: 1em;
    display: flex;
    align-items: stretch;
  }

  .ui-card-powder {
    /* background-color: #eae7dc!important; */
    background-color:LightGray!important;
    font-weight: bolder;
    color: #416051;
    border: 2px groove SteelBlue;
    /* border-style: groove; */
  }

  .ui-card-border {
    border: 2px solid #4682b4;
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
  }

  .dashed {
    border-style: dashed;
  }

 .ui-card-title {
    text-align: center;
 }  

 .ui-card-footer {
  text-align: center;
}  

 .ui-dialog-content Dialog{
    font-size: 12px !important;
    font-weight: bolder;
 }

 #walletronDomainRadioButton .ui-state-active {
	color: blue ;
    background: blue;
    border: solid lightgray;
    font-size: 12px !important;
    font-weight: 300;
}

.top-buffer { margin-top:20px; }

[hidden] {
    display: none !important;
  }

.ui-tree.task-tree {
    width: 50%;
  }

.margin-left{
    margin-left: 2% !important;
}

.group {
  margin-bottom: 10px;
}

.group label {
  font-size: 12px;
}

label {
  color: darkblue
}

.pageTitle {
  font-family:Arial, Helvetica, sans-serif;
  color: white; 
  background:   #7b95a3;
  margin: center;
  text-align: center;
  vertical-align: middle;
    /* position: left; */
  text-decoration: none;
  border-radius: 2px;
  padding: 2px;
  border: 1px solid lightblue;
  border: inset 1px solid #333;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3); */
}

.criteriaBox {
  background: white;
  margin: left;
  /* position: left; */
  text-decoration: none;
  border-radius: 2px;
  padding: 2px;
  border: 1px solid lightblue;
  border: inset 1px solid #333;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3); */
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  border-top: 16px solid blue;
  border-right: 16px solid green;
  border-bottom: 16px solid red;
  width: 12px;
  height: 12px;
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.div-criteria {
  background-color: #7b95a3;
  border: 1px solid rgb(52, 73, 94) ;
  height: 50px;
}

.div-criteria-two {
  background-color: Gainsboro!important;
  border-radius: 5px;
  padding-top: 15px;
  border: 2px groove SteelBlue;
}

.div-box {
  color: #DCDCDC;
  background-color: #DCDCDC;
  border: 1px solid rgb(52, 73, 94) ;
 
}

.mat-toolbar-row{background: #2726DD}

.float-right {
  float: right;
}

.float-center {
  display: flex;
  align-items: center;
  justify-content:space-between;
}

.flex-container {
  display: flex;
}

.flex-child {
  flex: 2;
}  

hr {
  border: white;
}  
  
.margin-bottom-narrow {
  margin-bottom:-20px;
}

.margin-bottom-semi-narrow {
  margin-bottom:-10px;
}

.margin-bottom-narrower {
  margin-bottom:-5px;
}

/* Fixes primeng default min width being too large */
.ui-dropdown {
min-width: 5% !important;
}

.dialog-margins {
  margin-top: -100px;
} 

.div-lightgray {
  background-color: lightgray;
}

.wal-center {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wal-dropdown {
  height: 2em;
}

.p-fileupload .p-fileupload-buttonbar {
  /* display: flex; */
  /* justify-content: center; */
  height: 3em;
}


input {
  height: 2.5em;
}

.p-datatable .p-datatable-header{
  text-align:center;
  font-size:16px;
} 

.env-banner{
  color: white;
  background-color: red;
  font-style:unset;
}

.green-banner{
  color: white;
  background-color: green;
  font-style:unset;
}

